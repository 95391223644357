<template>
  <section>
    <b-row>
      <b-col lg="12" md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>BITO 比特币期货 ETF </b-card-title>
          </b-card-header>
        </b-card>
      </b-col>

      <b-col>
        <b-card no-body class="prosharesPrice">
          <b-card-header>
            <b-card-title> 价格信息 {{ time }} </b-card-title>
          </b-card-header>
          <b-card-body style="height: 303px">
            <table class="proPrice">
              <tr>
                <td>
                  <div class="name">资产净值</div>
                  <div class="price">$ {{ priceData.nav }}</div>
                </td>
                <td>
                  <div class="name">资产净值变化</div>
                  <div class="price">$ {{ priceData.nav_change }}</div>
                </td>
                <td>
                  <div class="name">市场价值</div>
                  <div class="price">
                    $ {{ priceData.market_price_closing }}
                  </div>
                </td>
                <td>
                  <div class="name">市场涨跌幅</div>
                  <div class="price">
                    $ {{ priceData.market_price_closing_change }}
                  </div>
                </td>
                <td>
                  <div class="name">每日成交量</div>
                  <div class="price">{{ priceData.daily_trading_volume }}</div>
                </td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="5">
        <b-card no-body class="prosharesPrice">
          <b-card-header>
            <b-card-title
              >Fund Holdings Information as of {{ datetime }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <el-table
              class="price"
              :data="tableData2"
              style="width: 100% ; background-color: transparent"
              height="280"
              :row-class-name="tableRowClassName"
            >
              <el-table-column prop="description" label="描述">
              </el-table-column>
              <el-table-column
                prop="exposure_value"
                label="美金价值"
                width="140"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.exposure_value == null
                      ? "-"
                      : scope.row.exposure_value
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="market_value"
                label="股票/合约"
                width="140"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.market_value == null
                      ? "-"
                      : scope.row.market_value
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="4" style="height">
        <b-card no-body class="prosharesPrice">
          <b-card-header> </b-card-header>
          <b-card-body>
            <customized-pie ref="prosharesPrice" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>基金规模(USD) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="fundScale" />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>持仓规模(USD) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="PositionSize" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { getBitoFundData, getBitoPriceData } from "@/api/BtcEtf.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import CustomizedPie from "@/views/charts-and-maps/charts/echart/option-echart/CustomizedPie.vue";
import Inventory from "@/views/charts-and-maps/charts/echart/option-echart/Inventory.vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    Inventory,
    CustomizedPie,
  },
  data() {
    return {
      tableData: [
        {
          name: "资产净值",
          price: "$40.91",
        },
        {
          name: "资产净值变化",
          price: "$-0.21",
        },
        {
          name: "市场收盘价格",
          price: "$40.89",
        },
        {
          name: "市场收盘价变化",
          price: "$-0.23",
        },
        {
          name: "日交易量",
          price: "5848809",
        },
        {
          name: "持仓",
          price: "21955 BTC",
        },
      ],
      tableData2: [],
      time: "", //bito价格信息时间
      datetime: "", //基金资产信息时间
      bitoTotal: {
        bitcoin: 200,
        bill: 300,
        billFirst: 1,
        cashFirst: 2,
      }, //基金资产信息
      fundTimeData: [], //基金规模xAxis data时间间隔
      YcharData: [], //基金规模yAxis data数据
      positionTimeData: [], //持仓xAxis data时间间隔
      positionData: [], //持仓yAxis data数据
      lsizeitTotal: {}, //基金资产信息
      tempData: [], //存放bito价格信息所有数据
      priceData: {}, //存放bito价格信息数据
    };
  },
  created() {
    this.getFundList();
    this.getPriceList();
  },
  mounted() {},
  methods: {
    //奇偶行背景色不同
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }

      return "evenRow";
    },
    //处理价格时间
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },

    //请求bito价格信息数据
    getPriceList() {
      getBitoPriceData()
        .then((res) => {
          this.tempData = [...res.data.data];

          this.priceData = this.tempData[0];
          //console.log(this.priceData)
          // console.log(1111, this.priceData);
          //显示最近日期
          this.time = this.dateFtt(
            "yyyy-MM-dd",
            new Date(this.priceData.datetime)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //处理圆饼数据用parseInt（）方法对两个数组数据进行相加保留整数
    getPaiData(bitDataNew, billDataNew, cashDataNew) {
      // console.log("bitdatanew", bitDataNew);
      for (let i = 0; i < bitDataNew.length; i++) {
        this.bitoTotal.bitcoin += parseInt(bitDataNew[i].exposure_value);
      }
      // console.log("-->",this.bitoTotal.bitcoin)
      for (let i = 0; i < billDataNew.length; i++) {
        this.bitoTotal.billFirst += parseInt(billDataNew[i].market_value);
      }
      // console.log("this.bitoTotal.billFirst",this.bitoTotal.billFirst)
      for (let i = 0; i < cashDataNew.length; i++) {
        this.bitoTotal.cashFirst += parseInt(cashDataNew[i].market_value);
      }
      // console.log("this.bitoTotal.cashFirst",this.bitoTotal.cashFirst)
      this.bitoTotal.bill = this.bitoTotal.billFirst + this.bitoTotal.cashFirst;
      // console.log("this.bitoTotal.bill",this.bitoTotal.bill)
    },
    //获取基金规模时间间隔
    getundTime(bitData, billData) {
      let set = new Set();
      // 遍历美金日期
      for (let i = 0; i < bitData.length; i++) {
        let day =
          new Date(bitData[i].datetime).getDate() < 10
            ? "0" + new Date(bitData[i].datetime).getDate()
            : new Date(bitData[i].datetime).getDate();
        set.add(new Date(bitData[i].datetime).getMonth() + 1 + "-" + day);
      }
      this.positionTimeData = Array.from(set);
      // 遍历基金日期
      for (let i = 0; i < billData.length; i++) {
        let day =
          new Date(billData[i].datetime).getDate() < 10
            ? "0" + new Date(billData[i].datetime).getDate()
            : new Date(billData[i].datetime).getDate();
        set.add(new Date(billData[i].datetime).getMonth() + 1 + "-" + day);
      }
      this.fundTimeData = Array.from(set);
      return this.fundTimeData;
    },
    //获取基金规模,持仓规模y轴数据
    getYcharData(date, bitData, billData) {
      //获取持仓规模y轴数据
      let positionDate = this.positionTimeData;

      for (let i = 0; i < positionDate.length; i++) {
        let positionount = 0;
        for (let b = 0; b < bitData.length; b++) {
          if (bitData[b].datetime.search(date[i]) != -1) {
            positionount += parseFloat(bitData[b].exposure_value / 100000000);
            // bitCount += (bitData[b].exposure_value / 100000000).toFixed(2);
          }
        }
        //bitArr.push(bitCount.toFixed(2));
        //获取持仓规模y轴数据
        this.positionData.push(positionount.toFixed(2));
      }

      let bitArr = [];
      //for循环遍历第一个数组bitData里面是同一天的所有exposure_value值取整相加放进空数组里面
      for (let i = 0; i < date.length; i++) {
        let bitCount = 0;
        for (let b = 0; b < bitData.length; b++) {
          if (bitData[b].datetime.search(date[i]) != -1) {
            bitCount += parseFloat(bitData[b].exposure_value / 100000000);
            // bitCount += (bitData[b].exposure_value / 100000000).toFixed(2);
          }
        }
        bitArr.push(bitCount.toFixed(2));
        // //获取持仓规模y轴数据
        // this.positionData.push(bitCount.toFixed(2));
      }
      let billArr = [];
      //for循环遍历第二个数组billData里面是同一天的所有exposure_value值取整相加放进空数组里面
      for (let i = 0; i < date.length; i++) {
        let bitCount = 0;
        for (let b = 0; b < billData.length; b++) {
          if (billData[b].datetime.search(date[i]) != -1) {
            bitCount += parseFloat(billData[b].market_value / 100000000);
          }
        }
        billArr.push(bitCount.toFixed(2));
      }

      var bitAndBill = [];
      //for循环把两个数组同一天的值相加添加到新的数组尾末
      //获取基金规模y轴值
      for (let i = 0; i < date.length; i++) {
        bitAndBill.push(
          (parseFloat(bitArr[i]) + parseFloat(billArr[i])).toFixed(2)
        );
      }
      return bitAndBill;
    },

    // 请求bito Fund Holdings Information基金控股数据
    getFundList() {
      getBitoFundData()
        .then((res) => {
          // 获取美金数据和基金数据
          let bitData = res.data.data.CME_BITCOIN_FUTURES;
          let billData = res.data.data.TREASURY_BILL;
          let cashData = res.data.data.Cash;
          // 获取美金数据和基金数据的第一个日期值
          // console.log(res.data.data);
          // console.log(bitData);
          let latelyDate_bil ="";
          let latelyDate_bit ="";
          let latelyDate_cash ="";

          if (res.data.data.TREASURY_BILL !== null) {
            this.latelyDate_bil = res.data.data.TREASURY_BILL[0].datetime;
          } else {
            this.latelyDate_bil = "--";
          }
          if (res.data.data.CME_BITCOIN_FUTURES !== null) {
            this.latelyDate_bit = res.data.data.CME_BITCOIN_FUTURES[0].datetime;
          } else {
            this.latelyDate_bit = "--";
          }
          if (res.data.data.Cash !== null) {
            this.latelyDate_cash = res.data.data.Cash[0].datetime;
          } else {
            this.latelyDate_cash = " --";
          }

          let billDataNew = billData.filter(function (value, index, array) {
            return value.datetime == this.latelyDate_bil;
          });
          let bitDataNew = bitData.filter(function (value, index, array) {
            return value.datetime == this.latelyDate_bit;
          });
          let cashDataNew = cashData.filter(function (value, index, array) {
            return value.datetime == this.latelyDate_cash;
          });

          // 获取美金价格和基金数据，组装为一个数组
          this.tableData2 = bitDataNew.concat(billDataNew).concat(cashDataNew);
          // Fund Holdings Information显示最近日期
          this.datetime = res.data.data.CME_BITCOIN_FUTURES[0].datetime.replace(
            "00:00:00",
            ""
          );

          //处理圆饼数据用parseInt（）方法对两个数组数据进行相加保留整数
          this.getPaiData(bitDataNew, billDataNew, cashDataNew);

          //获取基金规模时间间隔
          this.getundTime(bitData, billData);

          // 遍历日期
          var bitArray = [];
          for (let i = 0; i < this.fundTimeData.length; i++) {
            // 遍历第一个数组中的值
            let bitCount = 0;
            for (let a = 0; a < bitData.length; a++) {
              // 根据日期
              if (bitData[a].datetime.search(this.fundTimeData[i]) != -1) {
                bitCount += parseInt(bitData[i].exposure_value);
                for (let b = 0; b < billData.length; b++) {
                  bitCount += parseInt(billData[b].shares_or_contracts);
                }
              }
            }
            bitArray.push(bitCount);
          }

          let date = this.getundTime(bitData, billData);
          //处理基金规模数据
          this.YcharData = this.getYcharData(date, bitData, billData);
          // console.log("->>", this.YcharData);

          this.dealEchartData(1, this.bitoTotal);
          this.fundScaleData(1);
          this.fundScaleData(2);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取圆饼两个data值
    dealEchartData(type, bitoTotal) {
      if (type === 1) {
        this.$refs.prosharesPrice.chart.setOption({
          tooltip: {
            trigger: "item",
          },
          visualMap: {
            show: false,
            min: 80,
            max: 600,
            inRange: {
              colorLightness: [0, 1],
            },
          },
          series: [
            {
              name: "BITO持仓比例",
              type: "pie",
              radius: "55%",
              center: ["50%", "50%"],
              data: [
                {
                  value: bitoTotal.bitcoin,
                  name: "CME期货持仓比例",
                  itemStyle: { color: "#EFC394" },
                },
                {
                  value: bitoTotal.bill,
                  name: "现金等价物持仓比例",
                  itemStyle: { color: "#161D31" },
                },
              ].sort(function (a, b) {
                return a.value - b.value;
              }),
              roseType: "radius",
              label: {
                color: "#FFFF",
              },
              labelLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                },
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
              itemStyle: {
                color: "#c23531",
                shadowBlur: 200,
                shadowColor: "rgba(0, 0, 0, 1.0)",
              },
              animationType: "scale",
              animationEasing: "elasticOut",
              animationDelay: function (idx) {
                return Math.random() * 200;
              },
            },
          ],
        });
      }
    },
    //获取基金规模data值
    fundScaleData(type) {
      if (type === 1) {
        this.$refs.fundScale.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          xAxis: {
            data: this.fundTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            nameTextStyle: {
              width: 100,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} 亿",
              textStyle: {
                color: "#FFF",
              },
            },
          },
          series: [
            {
              name: "资产净值",
              data: this.YcharData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#6FB8F7",
              },
            },
          ],
        });
      } else if (type === 2) {
        this.$refs.PositionSize.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },

          xAxis: {
            data: this.positionTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} 亿",
              textStyle: {
                color: "#FFF",
              },
            },
          },
          series: [
            {
              name: "持仓",
              data: this.positionData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#EFC394",
              },
            },
          ],
        });
      }
    },
  },
};
</script>
<style lang="scss">
.proPrice {
  width: 100%;
  td {
    text-align: left;
    border-bottom: 1px solid rgba(247, 235, 235, 0.2);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    color: #ffff;
    .name {
      width: 70%;
    }
    .price {
      width: 30%;
    }
  }
}
.prosharesPrice {
  .card-header .card-title {
    font-size: 16px;
    font-weight: 600;
    color: #ffff;
  }
  .price {
    .el-table__header-wrapper {
          .has-gutter {
            tr {
              border-bottom: none !important;
              .el-table__cell {
                text-align: center;
                color: rgba(239, 195, 148, 1) !important;
                background-color: rgba(45, 47, 58, 1) !important;
                font-size: 14px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              }
            }
          }
        }
    //background: #222633 !important;
    .your-table .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    //滚动条的滑块
    .your-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #a1a3a9;
      border-radius: 3px;
    }
    //如果是整个页面的滚动条风格是一致的，直接改全局的滚动条样式也可以有效果
    //滚动条的宽度
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    //滚动条的滑块
    ::-webkit-scrollbar-thumb {
      background-color: #efc394;

      border-radius: 3px;
    }
    th {
      // text-align: center !important;
      color: #ffff;
      background-color: #283046 !important;
      border-bottom: none !important;
    }
    tr {
      background: #33353e;
      border-bottom: none !important;
      .el-table__cell {
        // text-align: center !important;
        border-bottom: none !important;
      }
    }
  }
}
</style>
<style lang="scss">
.oddRow {
  //斑马纹样式

  color: white;

  background-color: rgba(102, 102, 102, 0.1) !important;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}

.evenRow {
  background-color: rgba(255, 255, 255, 0.1) !important;

  color: white;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}
</style>